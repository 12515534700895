import React from "react"
import { graphql, useStaticQuery } from "gatsby"
//data
const getData = graphql`
  {
    a: allContentfulFiche(filter: { notDinaux: { eq: false } }) {
      nodes {
        name
      }
      totalCount
    }
    b: allContentfulFiche {
      nodes {
        name
      }
      totalCount
    }
  }
`
const Chiffres = () => {
  const data = useStaticQuery(getData)

  return (
    <div className="stat">
      <h2>Chiffres</h2>
      <table>
        <tbody>
          <tr>
            <td>Nombre de personne répertorié :</td>
            <td>{data.b.totalCount}</td>
          </tr>
          <tr>
            <td>Nombre de Dinaux répertorié :</td>
            <td>{data.a.totalCount}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default Chiffres
