import React from "react"
//component
import Menu from "../components/Menu"
import Chiffres from "../components/stats/Chiffres"
import HommeFemme from "../components/stats/HommeFemme"
//css
import "../css/nav.scss"
//data

const Stat = () => {
  return (
    <div>
      <div className="navTree">
        <fieldset>
          <h2>Navigation</h2>
          <Menu />
        </fieldset>
      </div>
      <h1>Statistiques</h1>
      <Chiffres />
      <HommeFemme />
    </div>
  )
}

export default Stat
